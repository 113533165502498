<template>
  <page class="discover-page" child style="background: #fff">
    <div class="search-group">
      <div class="search-group-back">
        <van-icon name="arrow-left" @click="$router.back()"/>
      </div>
      <div class="input-group">
        <div class="input">
          <input type="text" placeholder="快速找到好资料" v-model="keyword" ref="keyword" @keydown.enter="goSearch"/>
        </div>
        <div class="remove-icon" v-if="keyword" @click="keyword = ''">
          <i class="iconfont icon-close"></i>
        </div>
        <i class="iconfont icon-search search-icon" @click="historyClick(keyword,null)"></i>
      </div>
    </div>

    <div class="tag-group">

      <div class="type">
        <div class="type-title">
          <span class="title">热门搜索</span>
        </div>

        <div class="type-body hide-children">
          <template v-for="(one, index) in history">
            <div class="type-item" @click="historyClick(one, index)">
              <span :class="historyActive === index && 'active'">{{ one }}</span>
            </div>
          </template>
        </div>

      </div>

      <template v-for="(type, index) in list">

        <div class="type">
          <div class="type-title">
            <span class="title">{{ type.title }}</span>
            <van-icon :name="type.show?'arrow-down': 'arrow-up'" @click="toggle(index)"/>
          </div>

          <div class="type-body" :class="type.show ? 'show-children': 'hide-children'">
            <template v-for="item in type.children">
              <div class="type-item">
                                <span @click="chooseItem(index,item,type.key)"
                                      :class="actives[index].id === item.id && 'active'">{{ item.name }}</span>
                <div class="type-item-children" v-show="type.show">

                  <template v-for="one in item.subPlanCategory">
                                        <span :class="actives[index].id === one.id && 'active-child'"
                                              @click="chooseItem(index,one,type.key)">{{ one.name }}</span>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>

      </template>


      <div class="btn-group">
        <a @click="goSearch">搜索</a>
      </div>
    </div>
  </page>
</template>

<script>

import {scheme} from '@/api'
import {configV2Api as configApiV2} from "../../api/v2/configV2Api";
import {searchHistoryV2Api} from "../../api/v2/searchHistoryV2Api";

let array = [
  {title: '按方案分', key: 'projPlanType', show: false},
  {title: '按活动分', key: 'activityType', show: false},
  {title: '按行业分', key: 'industryType', show: false},
  {title: '参与人数', key: 'enjoyAmount', show: false},
  {title: '项目预算', key: 'projectBudget', show: false},
  {title: '场地类型', key: 'placeType', show: false},
];

export default {
  data() {
    return {
      keyword: '',
      actives: [{}, {}, {}, {}, {}, {}, {}],
      tree: {},
      active: {},
      history: [],
      list: [],
      historyActive: null,
      visibleOption: {
        HOME_ENJOY_AMOUNT_VISIBLE: 'N',
        HOME_PROJECT_BUDGET_VISIBLE: 'N',
        HOME_CATEGORY_EXPAND_VISIBLE: 'N'
      }
    }
  },

  async created() {
    await this.loadVisibleOption();
    this.getHistory();
    await this.getByParent();
    this.initActives();
  },

  mounted() {
    const {focusInput, keyword} = this.$route.query;
    if (focusInput === 'true') {
      this.$refs.keyword.focus();
    }
    this.keyword = keyword || '';
  },

  methods: {
    // 加载是否可见
    async loadVisibleOption() {
      const codes = Object.keys(this.visibleOption);
      const res = await configApiV2.findByCodes(codes);
      for (const row of res) {
        this.visibleOption[row.code] = row.value;
      }
    },

    //获取热门搜索
    async getHistory() {
      const res = await searchHistoryV2Api.findMy()
      this.history = res.hots
        .filter(row => row.targetType === 1)
        .map(row => row.keyword)
        .slice(0,6)
    },
    //通过parentid获取分类
    async getByParent() {
      const {data} = await scheme.tree();
      this.tree = data;
      this.list = array
        .filter((item) => {
          switch (item.key) {
            case 'enjoyAmount':
              return this.visibleOption.HOME_ENJOY_AMOUNT_VISIBLE === 'Y';
            case 'projectBudget':
              return this.visibleOption.HOME_PROJECT_BUDGET_VISIBLE === 'Y';
            default:
              return true;
          }
        })
        .map(item => ({
          title: item.title,
          children: data[item.title],
          key: item.key,
          show: item.show,
          showSubChild: false,
          actives: {}
        }));

    },


    historyClick(item, index) {
      this.keyword = item;
      let url = `${window.location.origin}/home?keyword=${item}`;
      window.location.replace(url);

    },

    toggle(index) {
      this.list[index].show = !this.list[index].show;
    },


    chooseItem(index, item, param) {
      // console.log(index, item, param);
      //if(item.subPlanCategory && item.subPlanCategory.length) return;
      if (this.actives[index].id == item.id) {
        this.actives[index] = {};
      } else {
        this.actives[index] = item;
        this.actives[index].parent = param;
      }

      this.$set(this.actives, index, this.actives[index]);
    },


    goSearch() {

      let result = [];
      this.actives.forEach(item => {
        if (Object.keys(item).length) {
          result.push(item);
        }
      });

      if (!result) return;

      let str = '';
      for (let i = 0; i < result.length; i++) {
        str += `${i === 0 ? '' : '&'}${result[i].parent}=${result[i].name}`;
      }
      if(this.keyword){
        searchHistoryV2Api.add(this.keyword,1)
      }

      let keywordStr = this.keyword && this.keyword.length ? `&keyword=${this.keyword}` : '';
      let url = `${window.location.origin}/home?${str}&active=0${keywordStr}`;
      window.location.replace(url);
    },

    initActives() {
      // console.log(this.list);
      const {query} = this.$route;
      for (const k in query) {
        const typeIndex = array.findIndex(a => a.key === k);
        if (typeIndex === -1) {
          continue;
        }
        const type = array[typeIndex];
        const items = this.list[typeIndex].children;
        if (!items || !items.length) {
          continue;
        }

        const item = items.find(s => s.name === query[k]);
        this.chooseItem(typeIndex, item, k);
      }
    }

  }
}
</script>

<style scoped lang="less">
.discover-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .search-group {
    background-color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-right: 28px;
    padding-left: 10px;
    padding-top: 6px;
    box-sizing: border-box;

    .search-group-back {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #151d36;
      left: 12px;
      top: 12px;
      padding-right: 10px;
    }

    .input-group {
      background: #f3f3f5;
      flex: 1;
      display: flex;
      align-items: center;
      border-radius: 35px;
      padding-left: 15px;
      padding-right: 15px;

      .search-icon {
        font-weight: 600;
        opacity: .3;
        font-size: 16px;
      }

      .remove-icon {
        width: 16px;
        height: 16px;
        background-color: rgba(0, 0, 0, .2);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 100%;
        cursor: pointer;

        i {
          font-size: 12px;
          transform: scale(.5);
        }
      }

      .input {
        height: 35px;
        flex: 1;

        input {
          width: 100%;
          outline: none;
          border: none;
          height: 35px;
          background-color: transparent;

          &::placeholder {
            color: #999;
          }
        }
      }
    }

    .search-btn-group {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px 0 15px;
      position: relative;

      .dot {
        position: absolute;
        background: red;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        right: 2px;
        top: -2px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

  }

  .tag-group {
    box-sizing: border-box;
    padding: 12px 24px;
    flex: 1;
    max-height: calc(100vh - 105px - 81px);
    overflow-y: auto;

    .type {
      display: flex;
      flex-direction: column;

      .type-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        i {
          transition: all .4s ease;
        }
      }

      .type-body {
        margin-top: 10px;
      }

      .type-item {
        margin-top: 10px;

        > span {
          width: calc((100vw - 68px) / 3);
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f3f3f5;
          border-radius: 18px;
          color: #999;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.active {
            color: #fff;
            background: var(--text-color);
          }
        }
      }

      .hide-children {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .type-item:nth-child(3n+2) {
          margin-left: 10px;
          margin-right: 10px;
        }

        .type-item {

        }
      }

      .show-children {
        .type-item-children {
          padding: 10px 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          span {
            display: flex;
            padding: 3px 10px;
            color: #999;

            &.active-child {
              color: #333;
            }
          }
        }
      }
    }

    .type:nth-child(n + 2) {
      margin-top: 24px;
    }
  }

  .btn-group {
    background: #fff;
    position: fixed;
    bottom: 55px;
    width: 100vw;
    height: 81px;
    left: 0;
    box-sizing: border-box;
    padding: 12px 24px 24px 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      width: 270px;
      height: 45px;
      background-color: @theme-color;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      border-radius: 22px;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
